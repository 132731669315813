.hello-there-tt::before {
    height: 300%;
    width: 300%;
    background: url("https://miro.medium.com/v2/resize:fit:960/1*oX2S3kaIrKJgcLQbjzYgpA@2x.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
    text-align: center;
}

.below-hero {
    background: url("https://i.imgur.com/1kP2hFs.jpg");
    display: grid;
    width: 100%;
    place-items: center;
    background-size: cover;
    background-position: center;
}

/* .below-hero>* {
    grid-column: 1;
    grid-row: 1;
} */
.ProjectCard {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

#projects>span,
#technologies>span {
    position: relative;
    z-index: 1;
}

#projects>span:before,
#technologies>span:before {
    border-top: 1rem solid #A855F7;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 55%;
    left: 5%;
    right: 0;
    bottom: 0;
    width: 98%;
    z-index: -1;
}

@media(min-width: 1024px) {

    #projects>span:before,
    #technologies>span:before {
        border-top: 3rem solid #A855F7;
    }

    .ProjectCard {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    
}

@media(max-width: 768px) {
    .ProjectCard {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}